import {Directive, ElementRef, HostListener, Optional} from '@angular/core';
import {NgControl} from "@angular/forms";

/**
 * Custom directive to allow only numeric input into an input field.
 * This directive works by removing any non-numeric characters and updating the value of the associated control.
 */
@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) { }

  /**
   * Event that is triggered every time the user types in the input field.
   * @param event Input event containing the change information.
   */
  @HostListener('input', ['$event']) onInputChange(event: any): void {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (this.ngControl?.control) {
      if (typeof this.ngControl.control?.value === 'number'){
        const numericValue: number | null = this.el.nativeElement.value ? parseInt(this.el.nativeElement.value, 10) : null;
        this.ngControl.control.setValue(numericValue, {emitEvent: false});
      }else{
        this.ngControl.control.setValue(this.el.nativeElement.value, {emitEvent: false});
      }
    }
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  /**
   * Event that is triggered when the user loses focus of the input field.
   */
  @HostListener('blur') onBlur(): void {
    const sanitizedValue = this.el.nativeElement.value.replace(/[^0-9]/g, '');
    this.el.nativeElement.value = sanitizedValue;

    if (this.ngControl?.control) {
      if(typeof this.ngControl.control?.value === 'number'){
        const numericValue: number | null = sanitizedValue ? parseInt(sanitizedValue, 10) : null;
        this.ngControl.control.setValue(numericValue);
      }else{
        this.ngControl.control.setValue(sanitizedValue);
      }
    }
  }

}
